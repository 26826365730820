<template>
  <div class="section" id="roadmap">
    <h1 class="title">Roadmap</h1>
    <div class="map">
      <div class="item right">
        <div class="divider"></div>
        <div class="info fade">
          <div class="time">Q3 2021</div>
          <p class="mt-10">Launch on GameFi,</p>
          <p class="mb-10">DAO Maker & Red Kite</p>
          <p class="mt-10">IGO (Initial Game Offering)</p>
          <p class="mb-10">Launchpad</p>
        </div>
      </div>
      <div class="item left">
        <div class="divider"></div>
        <div class="info fade">
          <div class="time">Q4 2021</div>
          <p class="mt-10">Marketplace</p>
          <p class="mt-10">Yield Guild</p>
          <p class="mt-10">Scholarship</p>
        </div>
      </div>
      <div class="item right">
        <div class="divider"></div>
        <div class="info fade">
          <div class="time">Q1 2022</div>
          <p class="mt-10 mb-10">Minigames</p>
          <p class="mt-10">Auction House</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Roadmap"
}
</script>

<style scoped>
  .section {
    margin-top: 100px;
    padding-top: 100px;
    background: #0A0A0A;
  }

  h1.title {
    background: url("../assets/roadmap.png");
    background-size: cover;
    aspect-ratio: 1440/231;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    text-transform: uppercase;
  }

  .map {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px var(--padding-section) var(--padding-section);
  }

  .item {
    position: relative;
  }

  .divider {
    position: relative;
    top: 9px;
    left: 0;
    margin: 0;
    height: 200px;
    width: 16px;
  }

  .divider:before {
    content: '';
    position: absolute;
    top: 0;
    left: 6px;
    height: 100%;
    width: 4px;
    background: url("../assets/dotted_mb.png");
    background-size: cover;
  }

  .divider:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 6px;
    background: var(--primary);
  }

  .info {
    position: absolute;
    padding: 0 60px 60px;
    top: 0;
  }

  .time {
    color: var(--primary);
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 10px;
  }

  .info p {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    white-space: nowrap;
  }

  .mt-10 {
    margin-top: 10px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .left .info {
    right: 100%;
    text-align: right;
  }

  .right .info {
    left: 100%;
  }

  @media screen and (max-width: 600px) {
    h1.title {
      background: url("../assets/roadmap_mb.png");
      background-size: cover;
      aspect-ratio: 375/100;
      font-size: 28px;
      line-height: 36px;
      text-transform: none;
    }

    .map {
      display: block;
    }

    .item {
      position: relative;
      padding: 0 60px 40px;
    }

    .time {
      margin-bottom: 8px;
      font-size: 16px;
      line-height: 28px;
    }

    .divider {
      position: absolute;
      left: 12px;
      height: 100%;
      width: 16px;
    }

    .info {
      position: static;
      padding: 0;
      text-align: left;
    }

    .info p {
      text-align: left;
      font-size: 16px;
      line-height: 28px;
    }

    .time {
      text-align: left;
    }
  }
</style>