<template>
  <div class="home-banner">
    <div class="banner-background">
      <div class="banner-countdown" v-if="isShowCountdown">
        <div class="countdown-content">
          <button class="close-countdown" @click="closeCountdown">
            <svg
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.53355 3.83148L7.51736 0.847568C7.71154 0.653477 7.71154 0.339659 7.51736 0.145568C7.32327 -0.0485227 7.00945 -0.0485227 6.81536 0.145568L3.83145 3.12948L0.847636 0.145568C0.653454 -0.0485227 0.339727 -0.0485227 0.145636 0.145568C-0.0485454 0.339659 -0.0485454 0.653477 0.145636 0.847568L3.12945 3.83148L0.145636 6.81539C-0.0485454 7.00948 -0.0485454 7.3233 0.145636 7.51739C0.242364 7.6142 0.369545 7.66284 0.496636 7.66284C0.623727 7.66284 0.750818 7.6142 0.847636 7.51739L3.83145 4.53348L6.81536 7.51739C6.91218 7.6142 7.03927 7.66284 7.16636 7.66284C7.29345 7.66284 7.42055 7.6142 7.51736 7.51739C7.71154 7.3233 7.71154 7.00948 7.51736 6.81539L4.53355 3.83148Z"
                fill="black"
              />
            </svg>
          </button>
          <div class="countdown-text">
            <div class="wrapper-text">
              <img src="../assets/black-logo.png" class="gamefi-icon" alt="" />
              <span>iGO</span>
              <a class="btn-view" href="https://hub.gamefi.org/#/pools/token" target="_blank" ref="noreferrer">
                CLICK HERE TO VIEW DETAILS
                <svg
                  width="20"
                  height="10"
                  viewBox="0 0 20 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.749315 3.89812H12.9149C14.2811 3.89812 14.9636 2.24472 13.9952 1.28098C13.7018 0.98892 13.7006 0.514287 13.9927 0.220841C14.2847 -0.0726426 14.7594 -0.0737293 15.0529 0.218292L18.9699 4.11641C18.9701 4.11664 18.9703 4.1169 18.9705 4.11712C19.2632 4.40918 19.2642 4.88535 18.9706 5.17839C18.9704 5.17861 18.9702 5.17887 18.9699 5.1791L15.0529 9.07722C14.7595 9.3692 14.2849 9.36819 13.9928 9.07467C13.7007 8.78122 13.7018 8.30659 13.9953 8.01453C14.9637 7.05078 14.2812 5.39739 12.9149 5.39739H0.749315C0.335291 5.39739 -0.000322342 5.06178 -0.000322342 4.64775C-0.000322342 4.23373 0.335291 3.89812 0.749315 3.89812Z"
                    fill="#72F34B"
                  />
                </svg>
              </a>
            </div>
            <div class="wrapper-time">
              <span class="time">
                <span class="number"> {{ formatNumber(time.days) }} </span>
                <span class="text"> Day{{ time.days > 1 ? "s" : "" }} </span>
              </span>
              <span class="colon">:</span>
              <span class="time">
                <span class="number"> {{ formatNumber(time.hours) }} </span>
                <span class="text"> Hour{{ time.hours > 1 ? "s" : "" }} </span>
              </span>
              <span class="colon">:</span>
              <span class="time">
                <span class="number"> {{ formatNumber(time.minutes) }} </span>
                <span class="text">
                  Minute{{ time.minutes > 1 ? "s" : "" }}
                </span>
              </span>
              <span class="colon">:</span>
              <span class="time">
                <span class="number"> {{ formatNumber(time.seconds) }} </span>
                <span class="text">
                  Second{{ time.seconds > 1 ? "s" : "" }}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <h1 class="banner-title">
        <div>Play-to-Earn</div>
        <div>Yield Guild & Launchpad</div>
      </h1>
      <div class="banner-subtitle">
        GameFi is the all-encompassing hub for game finance. The platform
        services blockchain gamers, investors, and traders in one Guild and
        Aggregator alongside a launchpad exclusively for games.
      </div>
      <div class="banner-btn primary" @click="scroll">Discover</div>
      <img alt id="xbox" src="../assets/xbox-controller.png" />
      <img alt id="ps5" src="../assets/ps5-controller.png" />
    </div>
    <div class="launchpad">
      <img alt src="../assets/launchpad.png"/>
    </div>
    <div class="intro" id="parallax">
      <div class="intro-row">
        <div class="intro-item fade">
          <img alt src="../assets/rocket.svg" />
          <div class="intro-item_title">For Game Projects</div>
          <ul class="intro-item_detail">
            <li>Eased holder and player relations</li>
            <li>Player-centric development funding</li>
            <li>Rapid exposure for game and its items</li>
          </ul>
        </div>
        <div class="intro-item fade">
          <img alt src="../assets/diamond.svg" />
          <div class="intro-item_title">For Game Investors</div>
          <ul class="intro-item_detail">
            <li>Eased onboarding of scholars</li>
            <li>First access to major games' tokens</li>
            <li>Better liquidity for game assets</li>
          </ul>
        </div>
        <div class="intro-item fade">
          <img alt src="../assets/gamepad.svg" />
          <div class="intro-item_title">For Game Players</div>
          <ul class="intro-item_detail">
            <li>No-cost access to play2earn games as a scholar</li>
            <li>Quick and easy access and tracking of all games</li>
            <li>Liquid market for all game items</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const formatNumber = (num) => {
  if (num < 10) {
    return String(`0${num}`);
  }
  return num;
};
const getDiffTime = (date1, date2) => {
  let difftime = date1 - date2;
  const days = Math.floor(difftime / 1000 / 60 / (60 * 24));
  difftime = difftime - days * 1000 * 60 * 60 * 24;
  const hours = Math.floor(difftime / 1000 / 60 / 60);
  difftime = difftime - hours * 1000 * 60 * 60;
  const minutes = Math.floor(difftime / 1000 / 60);
  difftime = difftime - minutes * 1000 * 60;
  const seconds = Math.floor(difftime / 1000);
  return {
    days,
    hours,
    minutes,
    seconds,
  };
};

function countdown(timestamp = 0) {
  const self = this;
  if (timestamp) {
    const time = +timestamp * 1000;
    if (time > Date.now()) {
      self.$data.isShowCountdown = true;
    }
    self.$data.time = getDiffTime(time, Date.now());

    self.$data.interval = setInterval(() => {
      const { days, hours, minutes, seconds } = self.$data.time;
      if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
        clearInterval(self.$data.interval);
        return;
      }
      self.$data.time = getDiffTime(time, Date.now());
    }, 1000);
  }
}
export default {
  name: "Banner",
  components: {},
  data() {
    return {
      isShowCountdown: false,
      time: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
    };
  },
  mounted() {
    const timestamp = 1631268000
    this.countdown(timestamp);
    function parallax() {
      let yScrollPosition = window.pageYOffset;
      // const el = document.getElementById("parallax");
      // el.style.bottom = 200 + yScrollPosition * 0.2 + "px";
      const xbox = document.getElementById("xbox");
      xbox.style.transform =
        window.innerWidth < 600
          ? `scale(0.3) rotate(${-yScrollPosition * 0.1}deg)`
          : `rotate(${-yScrollPosition * 0.1}deg)`;
      const ps5 = document.getElementById("ps5");
      ps5.style.transform =
        window.innerWidth < 600
          ? `scale(0.7) rotate(${yScrollPosition * 0.05}deg)`
          : `rotate(${yScrollPosition * 0.05}deg)`;
      requestAnimationFrame(parallax);
    }
    parallax();
  },
  methods: {
    countdown,
    formatNumber,
    closeCountdown() {
      this.$data.isShowCountdown = false;
      clearInterval(this.$data.interval);
    },
    scroll() {
      const el = document.getElementById("parallax");
      // el.scrollIntoView({behavior: 'smooth'})
      document.documentElement.scroll({
        top: el.getBoundingClientRect().top * 0.8,
        behavior: "smooth",
      });
    },
  },
  destroyed() {
    clearInterval(this.$data.interval);
  },
};
</script>

<style scoped>
.home-banner {
  position: relative;
  display: block !important;
  margin-top: 90px;
}

.banner-background {
  aspect-ratio: 1440 / 680;
  background: url("../assets/banner.png") no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.banner-background *:not(img):not(.crowdloan) {
  position: relative;
  z-index: 1;
}

.crowdloan {
  position: absolute;
  top: 100px;
  left: 10%;
  width: 80%;
  background: url("../assets/mesh_graph.png") no-repeat;
  background-size: cover;
  padding: 20px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-weight: 600;
}

.crowdloan-title {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 6px;
}

.crowdloan-detail {
  font-size: 20px;
  line-height: 28px;
}

.crowdloan a {
  font-size: 14px;
  line-height: 24px;
  color: white;
  text-decoration: none;
  white-space: nowrap;
  background: #0a0a0a;
  border: 1px solid #ff0095;
  border-radius: 4px;
  padding: 8px 56px;
}

.banner-countdown {
  width: 100%;
  padding: 0px var(--padding-section);
  margin-top: 10px;
}

.countdown-content {
  background-image: url(../assets/bg-banner-1.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 70px;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}

.close-countdown {
  position: absolute !important;
  top: 10px;
  right: 10px;
  width: 18px;
  height: 18px;
  background: #d4d4d4;
  border-radius: 50%;
  outline: none;
  border: none;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  z-index: 100 !important;
}

.close-countdown:hover {
  background: #aeaeae;
}

.countdown-text {
  /* display: grid;
  grid-template-columns: repeat(auto-fill, minmax(auto, auto)); */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.countdown-text .wrapper-text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.countdown-text .wrapper-text > img {
  /* display: inline-block; */
  margin-right: 14px;
  height: 26px;
}

.countdown-text .wrapper-text > span {
  color: #000000;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.02em;
  margin-right: 24px;
}

.countdown-text .wrapper-text > .btn-view {
  text-decoration: none;
  background: #000000;
  border: 2px solid #74d257;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px #2c4923;
  border-radius: 4px;
  font-family: Firs Neue;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #72f34b;
  mix-blend-mode: normal;
  /* height: 32px; */
  padding: 8px 28px;
  cursor: pointer;
  margin-right: 39px;
  /* max-height: 32px; */
}

.wrapper-time {
  display: grid;
  gap: 12px;
  grid-template-columns: 60px 10px 60px 10px 60px 10px 60px;
  height: 100%;
  background: #000;
  transform: skew(-30deg);
  padding: 10px 30px;
}

.wrapper-time .time {
  display: grid;
  place-content: center;
  place-items: center;
}

.wrapper-time .colon {
  text-align: center;
  top: 8px;
}
.wrapper-time .time .number {
  font-family: Space Ranger;
  /* font-style: italic; */
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  font-size: 36px;
  line-height: 40px;
}

.wrapper-time .time .text {
  font-family: Firs Neue;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #ffffff;
  mix-blend-mode: normal;
  transform: skew(30deg);
}

.banner-title {
  margin-top: 240px;
  font-family: "Space Ranger";
  font-size: 88px;
  line-height: 78px;
  text-align: center;
  width: 1100px;
}

.banner-title div:first-child {
  font-weight: 600;
}

.banner-title div:last-child {
  font-weight: 400;
}

.banner-subtitle {
  font-size: 20px;
  line-height: 32px;
  margin-top: 20px;
  text-align: center;
  width: 970px;
}

.banner-btn {
  background: #21361a;
  padding: 16px 80px;
  margin-top: 40px;
  cursor: pointer;
}

#xbox {
  position: absolute;
  top: 80px;
  left: 20px;
}

#ps5 {
  position: absolute;
  right: -160px;
  bottom: 0;
}

.launchpad img {
  width: 100%;
}

.intro {
  background: #171717;
  padding: 80px 160px;
}

.intro-row {
  display: flex;
}

.intro-item {
  flex: 1 1 0;
  margin-right: 40px;
}

.intro-item:last-child {
  margin-right: 0;
}

.intro-item_title {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 12px;
}

.intro-item_detail {
  font-size: 18px;
  line-height: 28px;
  color: #d1d1d1;
  margin-bottom: 8px;
  list-style: none;
}

.intro-item_detail li {
  display: flex;
}

.intro-item_detail li:before {
  content: "";
  min-width: 5px;
  height: 5px;
  background: var(--primary);
  margin-top: 12px;
  margin-right: 12px;
}

@media screen and (max-width: 1280px) {
  .wrapper-text {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .wrapper-time {
    width: 100%;
    transform: unset;
    place-content: center;
  }

  .wrapper-time .time {
    transform: skew(-30deg);
  }
}

@media screen and (max-width: 600px) {
  .home-banner {
    margin-top: 60px;
  }
  .banner-background {
    aspect-ratio: 375/600;
    background: url("../assets/banner_mb.png") no-repeat;
    background-size: cover;
  }

  .crowdloan {
    position: relative;
    top: 80px;
    left: 0;
    width: 90%;
    flex-direction: column;
    padding: 20px 10px;
  }

  .crowdloan-title {
    text-align: center;
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    text-transform: uppercase;
  }

  .crowdloan-detail {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
  }

  .crowdloan a {
    margin-top: 12px;
    padding: 4px 40px;
  }

  .banner-title {
    margin-top: 80px;
    font-size: 48px;
    line-height: 40px;
    width: 320px;
  }

  .banner-title > div {
    display: inline;
  }

  .banner-subtitle {
    margin-top: 12px;
    font-size: 14px;
    line-height: 22px;
    width: 280px;
  }

  #ps5 {
    bottom: -90px;
    right: -140px;
    transform: scale(0.7);
  }

  #xbox {
    /* top: -20px; */
    top: 75px;
    left: -90px;
    transform: scale(0.3);
  }

  .intro {
    position: static;
    padding: 40px;
    transform: none;
  }

  .intro-row {
    flex-direction: column;
  }

  .intro-item {
    padding-right: 0;
    padding-top: 40px;
  }

  .countdown-text .wrapper-text > img {
    margin-right: unset;
  }

  .countdown-text .wrapper-text > span {
    margin-right: unset;
  }

  .countdown-text .wrapper-text > .btn-view {
    margin-right: unset;
  }

  .wrapper-time {
    gap: 12px;
    grid-template-columns: 40px 10px 40px 10px 40px 10px 40px;
    padding: 5px 30px;
  }

  .wrapper-time .time .number {
    font-size: 28px;
    line-height: 28px;
  }

  .wrapper-time .time .text {
    font-size: 8px;
    line-height: 10px;
  }

  .close-countdown {
    display: none;
  }
}
</style>