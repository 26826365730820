<template>
  <div id="intro">
    <div class="intro">
      <img alt class="header" src="../assets/gameguild.png"/>
      <img alt class="header__mb" src="../assets/gameguild.png"/>
      <div class="content" style="padding-right: 0">
        <div class="detail fade">
          <h1>Yield Guild and <br/>Scholarship</h1>
          <p>
            BSC and Polygon have rapidly attracted some of the leading games in the blockchain space. Many of these games are actively deploying play2earn models to drive adoption and player-ownership.
          </p>
          <p>
            GameFi is the yield guild for gamers and token holders of BSC & Polygon games. 
          </p>

        </div>
        <img alt src="../assets/guild.png"/>
      </div>
    </div>
    <div class="early-partner">
      <p>SOME OF OUR EARLY PARTNERS</p>
      <div class="partners">
        <img alt src="../assets/derace.png"/>
        <img alt src="../assets/stephero.png"/>
        <img alt src="../assets/kabyarena.png"/>
        <img alt src="../assets/goldfever.png"/>
        <img alt src="../assets/dinox.png"/>
      </div>
    </div>
    <div class="intro">
      <img alt class="header" src="../assets/gamefi.png"/>
      <img alt class="header__mb" src="../assets/gamefi_mb.png"/>
      <div class="content">
        <img alt src="../assets/intro.png"/>
        <div class="detail fade">
          <h1>Game and Item <br/>Aggregator</h1>
          <p><span class="primary fw-700">GameFi</span> provides a selection of the industry’s most creative games and the first marketplace for trading items cross game borders.</p>
          <p><span class="primary fw-700">Gamers</span> can access any blockchain’s games in one place, with tools to track their Play to Earn yields and
            item net values.</p>
          <p><span class="primary fw-700">Traders</span> can buy or sell yield-bear game items of any game, from any blockchain, on a single platform. </p>
        </div>
      </div>
    </div>
    <h1 class="title">
      <div>Dedicated Gaming</div>
      <div>Accelerator & Launchpad</div>
      <div class="subtitle">
        Gaming and crypto both recognize utility of digital assets and the value of scarcity. The boom in blockchain
        gaming was inevitable. GameFi is pioneering a framework for crypto games to advance their project delivery
        through pre-designed, ready-to-integrate smart contracts.
      </div>
    </h1>
    <div class="grid slide">
      <div class="item slideLeft">
        <img alt src="../assets/accelerator.svg"/>
        <div>Accelerator</div>
        <p>Developers and teams working on blockchain games can greatly accelerate their delivery target by adopting
          factory contracts for token economies, NFTs, and Play to Earn mechanics.</p>
      </div>
      <img alt src="../assets/image1.png" class="slideRight"/>
      <img alt src="../assets/image2.png" class="slideLeft delay"/>
      <div class="item slideRight delay">
        <img alt src="../assets/rocket.svg"/>
        <div>Launchpad & IGO</div>
        <p>Crypto games have earned recognition as some of the most profitable blockchain products. Gaming items & tokens are
          extremely desirable. GameFi is the first IGO (Initial Game Offering) launchpad, with tools to facilitate the success of
          games.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Introduction"
}
</script>

<style scoped>
#intro {
  margin-top: 100px;
  padding-top: 60px;
}

.launchpad img {
    max-width: 100%;
}

.intro {
  position: relative;
  margin-top: 100px;
  padding-top: 60px;
}

.bg-black {
  background: #0A0A0A;
  padding: 100px 0;
}

.bg-black .header {
  top: 60px;
}

.early-partner {
  display: flex;
  align-items: center;
  padding: 20px;
  margin: 20px var(--padding-section);
  border-top: 1px solid #FFFFFF20;
  border-bottom: 1px solid #FFFFFF20;
}

.early-partner .partners {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 0 20px;
  flex-wrap: wrap;
}

.header,
.header__mb {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.header__mb {
  display: none;
}

.content {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--padding-section);
}

.detail {
  max-width: 550px;
}

.detail h1 {
  font-weight: 600;
  font-size: 48px;
  line-height: 56px;
  margin-bottom: 32px;
}

.detail p {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 16px;
  color: #D1D1D1;
}

.row {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.title {
  background: url("../assets/gamefi.png") no-repeat;
  background-size: cover;
  aspect-ratio: 1440/231;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 80px;
  position: relative;
}

.subtitle {
  width: 1060px;
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  color: #D1D1D1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 60px);
}

.grid {
  margin-top: 120px;
  padding: 0 var(--padding-section);
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.grid img {
  width: 100%;
}

.item {
  padding: 48px 60px;
  background: #0A0A0A;
}

.item img {
  width: 100px;
  margin-bottom: 20px;
}

.item div {
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 12px;
}

.item p {
  font-size: 18px;
  line-height: 28px;
  color: #D1D1D1;
}

@media screen and (max-width: 600px) {
  #intro {
    margin-top: 0;
    padding-top: 0;
  }

  .header {
    display: none;
  }

  .header__mb {
    display: block;
  }

  .launchpad ~ .intro {
    margin-top: 0;
  }

  .content {
    flex-direction: column-reverse;
  }

  .detail h1 {
    font-size: 28px;
    line-height: 36px;
  }

  .detail p {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 32px;
  }

  .early-partner {
    flex-direction: column;
  }

  .early-partner .partners {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    margin-top: 24px;
  }

  .title {
    background: url("../assets/gamefi_mb.png") no-repeat;
    background-size: cover;
    aspect-ratio: 375/100;
    text-align: left;
    font-size: 28px;
    line-height: 36px;
  }

  .title div {
    width: 300px;
  }

  .subtitle {
    text-align: left;
    width: 300px;
    font-size: 14px;
    line-height: 22px;
    margin-top: 12px;
    position: static;
    transform: none;
  }

  .grid {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    padding: 0;
    margin-top: 40px;
  }

  .grid img.slideLeft {
    order: 2;
  }

  .item {
    padding: 40px;
  }

  .item img {
    width: 48px;
    margin-bottom: 12px;
  }

  .item div {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 8px;
  }

  .item p {
    font-size: 14px;
    line-height: 22px;
  }
}
</style>